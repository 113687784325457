const config = {
  "broker": "3",
  "name": "Dutch",
  "registrationBundle": "dutch",
  "defaultCountryName": "Netherlands",
  "defaultCountryCode": "NL",
  "fxgeoip_code": "NL",
  languageCode: 'nl',
  languageName:'Dutch',
  spokenLanguageCode:'nl',
  direction:'ltr',
  footerDisclaimer:'899',
  brand: 'iforex'
};
module.exports = config;